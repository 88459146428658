
import { defineComponent, nextTick, onMounted } from "vue";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { initializeComponents } from "@/core/plugins/keenthemes";
import axios from "axios";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "app",
  setup() {
    const router = useRouter();

    axios.interceptors.response.use(undefined, function (err) {
      return new Promise(function (resolve, reject) {
        if (err.response.status === 403) {
          router.push("/403");
        }
        throw err;
      });
    });

    const store = useStore();

    onMounted(() => {
      nextTick(() => {
        initializeComponents();
      });
    });
  },
});
